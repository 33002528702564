import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import logo from '../../assets/logo-kapps.svg';
import {
  Container,
  HeaderContainer,
  DesktopContainer,
  Item,
  Logo,
  LogoutIcon,
  LogoutIconContainer,
  LogoutText,
  MenuIcon,
  MobileBackground,
  MobileContainer,
  MobileContent,
  MobileItem,
  ModalBackground,
  ModalContainer,
  CheckBoxInput,
  ModalBody,
  ModalButton,
  PkContainer,
  PkBody,
  PkExportButton,
} from './styles';

import { INavbarItem, navbarItems } from '../../configs/navbar';
import ConnectWallet from './ConnectWallet';
import { BsWalletFill } from 'react-icons/bs';
import { VscKey } from 'react-icons/vsc';
import { getNetwork } from '../../utils';
import CopyWrapper from 'components/CopyWrapper';
import { CopyIcon } from 'components/Accordion/styles';
import { useConnection } from 'hooks/ConnectionProvider';

const Navbar: React.FC = () => {
  const location = useLocation();
  const network = getNetwork();

  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showPk, setShowPk] = useState(false);
  const connection = useConnection();

  const [walletAddress] = useState(
    sessionStorage.getItem('walletAddress') || '',
  );

  useEffect(() => {
    if (walletAddress !== '') {
      sessionStorage.setItem('walletAddress', walletAddress);
    }
  }, [walletAddress]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'visible';
  }, [isOpen]);

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const NavbarItem: React.FC<INavbarItem> = ({ name, Icon, pathTo }) => {
    return (
      <Link to={pathTo}>
        <Item selected={location.pathname.includes(name.toLowerCase())}>
          <Icon />
          <span>{name}</span>
        </Item>
      </Link>
    );
  };

  const MobileNavbarItem: React.FC<INavbarItem> = ({ name, Icon, pathTo }) => {
    return (
      <Link to={pathTo}>
        <MobileItem
          onClick={handleMenu}
          selected={location.pathname.includes(name.toLowerCase())}
        >
          <span>{name}</span>
          <Icon />
        </MobileItem>
      </Link>
    );
  };

  const getNavbarItems = () => {
    const address = sessionStorage.getItem('walletAddress');
    let navItems = navbarItems;

    if (address) {
      navItems = [
        ...navItems,
        {
          name: 'Wallet',
          pathTo: '/wallet',
          Icon: BsWalletFill,
        },
      ];
    }

    return navItems;
  };

  const handleLogout = () => {
    sessionStorage.removeItem('walletAddress');
    sessionStorage.removeItem('privateKey');
    window.location.reload();
  };
  const ExportPk = () => {
    return (
      <ModalBackground>
        <ModalContainer>
          <ModalBody>
            {showPk ? (
              <RenderPk />
            ) : (
              <>
                <h2>Warning</h2>
                <span>
                  Do not share your private key or mnemonic words with anyone.
                  Anyone who has access to your private key or mnemonic words
                  will have total control over your account and funds.
                </span>
                <div>
                  <span>I Understand</span>
                  <CheckBoxInput
                    type="checkbox"
                    checked={checked}
                    onClick={() => {
                      setChecked(!checked);
                    }}
                  />
                </div>
                <div>
                  <ModalButton onClick={() => setShowModal(false)}>
                    Cancel
                  </ModalButton>
                  <ModalButton
                    isSuccess={true}
                    disabled={!checked}
                    onClick={() => setShowPk(true)}
                  >
                    Proceed
                  </ModalButton>
                </div>
              </>
            )}
          </ModalBody>
        </ModalContainer>
      </ModalBackground>
    );
  };

  const RenderPk = () => {
    const getPkSessionStorage = sessionStorage.getItem('privateKey');
    return (
      <PkContainer>
        <h3>Private Key</h3>
        <PkBody>
          <h4>{getPkSessionStorage}</h4>
          <CopyWrapper
            value={sessionStorage.getItem('privateKey') || ''}
            width={10}
          >
            <CopyIcon style={{ fontSize: '20px' }} />
          </CopyWrapper>
        </PkBody>
        <ModalButton
          onClick={() => {
            setShowModal(false);
            setShowPk(false);
            setChecked(false);
          }}
        >
          Close
        </ModalButton>
      </PkContainer>
    );
  };

  return (
    <>
      <Container>
        <HeaderContainer>
          <Link to="/wallet">
            <Logo onClick={handleClose}>
              <img src={logo} alt="Logo" width="224" height="28" />
            </Logo>
          </Link>
          <span>Running on KleverChain {network}</span>
        </HeaderContainer>

        {walletAddress && (
          <DesktopContainer>
            {getNavbarItems().map((item, index) => (
              <NavbarItem key={String(index)} {...item} />
            ))}
            <ConnectWallet walletAddress={walletAddress} />
            {!connection.checkIsExtension() ? (
              <PkExportButton>
                <VscKey
                  style={{ color: '#646693', fontSize: '1.3rem' }}
                  onClick={() => setShowModal(true)}
                />
              </PkExportButton>
            ) : null}

            <LogoutIconContainer>
              <LogoutIcon onClick={handleLogout} />
            </LogoutIconContainer>
            {showModal && <ExportPk />}
          </DesktopContainer>
        )}

        <MobileContainer>
          {walletAddress && <MenuIcon onClick={handleMenu} />}
        </MobileContainer>
      </Container>

      <MobileBackground onClick={handleClose} opened={isOpen} />

      <MobileContent opened={isOpen}>
        {getNavbarItems().map((item, index) => (
          <MobileNavbarItem key={String(index)} {...item} />
        ))}
        <ConnectWallet walletAddress={walletAddress} />
        <LogoutIconContainer>
          {window.matchMedia('(max-device-width: 768px)').matches ? (
            <>
              <LogoutText>Logout</LogoutText>
              <LogoutIcon onClick={handleLogout} />
            </>
          ) : (
            <LogoutIcon onClick={handleLogout} />
          )}
        </LogoutIconContainer>
      </MobileContent>
    </>
  );
};

export default Navbar;
