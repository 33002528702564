export const placeholder = `{
  "RawData": {
      "Nonce": 1,
      "Sender": "1C8MMIwWxmyaouWbFrh3BLNFZthx4VtY2i2VSXbELWM=",
      "Contract": [
          {
              "Parameter": {
                  "type_url": "type.googleapis.com/proto.TransferContract",
                  "value": "CiAqLq6UNEEsR0gew0vIDQ8gwNPs/RlxQ5I6XrANb22wlxIDS0ZJGICglKWNHQ=="
              }
          }
      ],
      "KAppFee": 500000,
      "BandwidthFee": 1000000,
      "Version": 1,
      "ChainID": "MTA4"
  },
  "Signature": [
       "yxDM+7dUS91T/Pla9B6tadUtToLzLR0ucB5embQWhEigSq0q4NfW+WqieZCHeQDo+uu0bEie7j0bpxDfUL8WCA==",
       "CHjGvUsouomT9hm30HR1PKTgNWzKBRD/Xw9qLUGM3ulAqZk0uDGzbtDDu7Cya0A2U1wNGSlhuTQ7eFagCitLBA=="
  ]
}`;
