import { Account } from '@klever/sdk';
import { useConnection } from 'hooks/ConnectionProvider';
import { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router';
import { ExtensionAccount } from 'utils/extensionAccount';

const PrivateRoutes: React.FC = ({ children, ...rest }) => {
  const sdk = useConnection();
  const account = sdk.getAccount();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const walletAddress = sessionStorage.getItem('walletAddress');
    const privateKey = sessionStorage.getItem('privateKey');

    if (walletAddress) {
      if (sdk.selectedConnection === 'extension') {
        sdk.setAccount(
          new ExtensionAccount(
            walletAddress,
            process.env.REACT_APP_DEFAULT_NODE_HOST ?? undefined,
          ),
        );
      } else {
        setLoading(false);
      }
    } else if (
      sdk.selectedConnection === 'pem' &&
      privateKey &&
      !account &&
      walletAddress
    ) {
      sdk.setAccount(
        new Account(
          walletAddress,
          privateKey,
          process.env.REACT_APP_DEFAULT_NODE_HOST ?? undefined,
        ),
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    sdk.setUrls({
      api:
        process.env.REACT_APP_DEFAULT_API_HOST ||
        'https://api.testnet.klever.finance/v1.0',
      node:
        process.env.REACT_APP_DEFAULT_NODE_HOST ||
        'https://node.testnet.klever.finance',
    });
  }, []);

  return (
    <>
      {!loading && (
        <Route
          {...rest}
          render={() =>
            sessionStorage.getItem('walletAddress') ? (
              <>{children}</>
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            )
          }
        />
      )}
    </>
  );
};

export default PrivateRoutes;
