/* eslint-disable @typescript-eslint/ban-types */

// SDX X EXTENSION DOCUMENTATION:
// SDK:
// createAccount --> not found // but OK
// generateMnemonic --> not used
// decodePEM --> not found // but OK
// getAddressFromPrivateKey --> not found // but OK
// parsePemFileData --> not found // but OK
// sendTransaction --> not found // but OK
// setApiUrl --> not found // but OK
// setNodeUrl --> not found // but OK
// setWalletAddress --> not found // but OK
// verifySignature --> not found // but OK

// same methods:
// address --> OK
// getWalletAddress --> OK
// provider --> OK
// signMessage --> OK

// dif methods:
// broadcast(sdk) -->  broadcastTransactions(ext)
// signTx(sdk) --> signTransaction(ext)

// especial method:
// returns the account which was built was a new  Account from sdk lib
// getAccount --> returns address, host , PK

// EXTENSION:
// uncovered by extension:
// getAccount.sendBuyOrder
// getAccount.sendUnfreeze
// getAccount.sendConfigITO
// getAccount.sendAssetTrigger
// getAccount.sendCreateAsset
// getAccount.sendCreateMarketplace
// getAccount.sendSellOrder
// getAccount.sendDelegate
// getAccount.sendFreeze
// getAccount.sendWithdraw
//  getAccount.getBalance

import React, { useState, createContext, useContext, useEffect } from 'react';
import { Account, core, IURLs } from '@klever/sdk';

const ConnectionContext = createContext({} as IConnection);

interface IConnection {
  extensionInstalled: boolean;
  setExtensionInstalled: React.Dispatch<React.SetStateAction<boolean>>;
  injectWasmScript: () => void;
  checkIsExtension: () => string | boolean;
  shouldReset: boolean;
  setShouldReset: React.Dispatch<React.SetStateAction<boolean>>;
  selectedConnection: string;
  setSelectedConnection: React.Dispatch<React.SetStateAction<string>>;
  // sdk props:
  isLoaded(): Promise<boolean>;
  getAccount(): Account | null;
  setAccount(account: Account | string): void;
  setUrls(newUrls: IURLs): void;
}

const injectWasmScript = () => {
  const sdkScript = document.createElement('script');
  sdkScript.src = './kleverSDK/kleverSDKLoader.js';
  document.body.appendChild(sdkScript);
};

const resetWindow = () => {
  if (typeof window !== 'undefined') {
    window.location.reload();
  }
};

const checkIsExtension = () => {
  if (typeof window === 'undefined') {
    return 'Window object not loaded.';
  }
  return !window.kleverWeb.decodePEM;
};

interface ISdkContext {
  isLoaded(): Promise<boolean>;
  getAccount(): Account | null;
  setAccount(account: Account): void;
  setUrls(newUrls: IURLs): void;
}

const ConnectionProvider: React.FC = ({ children }) => {
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState('');

  const [acc, setAcc] = useState<Account | null>(null);
  const [urls, setUrls] = useState<IURLs>({
    api:
      process.env.REACT_APP_DEFAULT_API_HOST ||
      'https://api.testnet.klever.finance/v1.0',
    node:
      process.env.REACT_APP_DEFAULT_NODE_HOST ||
      'https://node.testnet.klever.finance',
  });

  const sdkValues: ISdkContext = {
    isLoaded: async () => !!acc,
    getAccount: () => acc,
    setAccount: account => setAcc(account),
    setUrls: newUrls => setUrls(newUrls),
  };

  useEffect(() => {
    if (selectedConnection === 'pem') {
      core.setURLs(urls);
    } else if (selectedConnection === 'extension') {
      window.kleverWeb.provider = urls;
    }
  }, [urls, selectedConnection]);

  useEffect(() => {
    if (shouldReset) {
      resetWindow();
    }
  }, [shouldReset]);

  const values = {
    ...sdkValues,
    extensionInstalled,
    setExtensionInstalled,
    injectWasmScript,
    checkIsExtension,
    shouldReset,
    setShouldReset,
    selectedConnection,
    setSelectedConnection,
  };

  return (
    <ConnectionContext.Provider value={values}>
      {children}
    </ConnectionContext.Provider>
  );
};

const useConnection = () => useContext(ConnectionContext);

export { ConnectionProvider, ConnectionContext, useConnection };
