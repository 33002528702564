import { Account, core } from '@klever/sdk';
import { Buffer } from 'buffer';
import Button from 'components/Button';
import Loader from 'components/Loading/Loader';
import { useDidUpdateEffect } from 'utils';
import { useConnection } from 'hooks/ConnectionProvider';
import PasswordModal from 'components/Modals/Password';
import { Container, Network, Title } from 'pages/styles';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getNetwork } from 'utils';
import {
  ButtonContainer,
  Content,
  ContentBody,
  ContentTitle,
  DragContainer,
  ErrorContainer,
  InfoIcon,
  InputFile,
  Header,
} from './styles';

const ConnectWallet: React.FC = () => {
  const [pemFile, setPemFile] = useState('');
  const [loading, setLoading] = useState(false);

  const [isDragging, setDragging] = useState(false);
  const [draggingOverlayCount, setDragginOverlayCount] = useState(0);

  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);

  const [error, setError] = useState('');

  const resetData = () => {
    sessionStorage.clear();
    setPemFile('');
    setLoading(false);
    setPassword('');
    setOpen(false);
  };

  const history = useHistory();

  const { injectWasmScript, setShouldReset, setAccount } = useConnection();

  const parserPemFile = (file: string) => {
    const splitter = '-----';
    const maxSplitSize = 5;

    if (!file.includes(splitter)) {
      return '';
    }

    const contents = file.split(splitter);
    if (contents.length !== maxSplitSize) {
      return '';
    }

    return contents;
  };

  const getPrivateKey = (contents: string | string[]) => {
    const privateKeyPosition = 2;

    if (contents === '') {
      return '';
    }

    return contents[privateKeyPosition];
  };

  const checkIsEncrypted = () => {
    if (pemFile.includes('ENCRYPTED')) {
      setOpen(true);
      return true;
    }
    return false;
  };

  const decodePrivateKey = (longPrivateKey: string, file: string): string => {
    let pk: string;

    if (checkIsEncrypted()) {
      pk = window.kleverWeb.decodePEM(file, password);
    } else {
      const decodedToBase64 = Buffer.from(longPrivateKey, 'base64');
      const decodedToHex = Buffer.from(decodedToBase64.toString(), 'hex');
      const privateKey = decodedToHex.slice(0, 32);

      pk = [...new Uint8Array(privateKey)]
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');
    }

    return pk;
  };

  const encodePrivateKey = (privateKey: string): string => {
    const encodedToBase64 = Buffer.from(privateKey, 'latin1').toString(
      'base64',
    );

    return encodedToBase64;
  };

  const getWalletAddress = (contents: string | string[]) => {
    const splitter = 'for ';
    const walletAddressPosition = 1;

    if (contents === '') {
      return '';
    }

    const parsed = contents[walletAddressPosition];
    const walletAddress = parsed.split(splitter)[walletAddressPosition];

    return walletAddress;
  };

  const readFile = (files: FileList) => {
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = /[^.]+$/.exec(file.name)![0];

      if (fileExtension !== 'pem') {
        setError('Invalid file format.');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        let result = e.target?.result;
        if (typeof result !== 'string') {
          result = '';
        }
        setPemFile(result);
        setError('');
      };

      reader.readAsText(file);
    }
  };

  const validatePemFile = () => {
    const contents = parserPemFile(pemFile);
    if (contents === '') {
      resetData();
      setError('Invalid PEM file.');
      return false;
    }

    const validatedPrivateKey = getPrivateKey(contents);
    if (validatedPrivateKey === '') {
      setError('Invalid Private Key.');
      resetData();
      return false;
    }

    const validatedWalletAddress = getWalletAddress(contents);
    if (validatedWalletAddress === '') {
      setError('Invalid Wallet Address.');
      resetData();
      return false;
    }
    return {
      walletAddress: validatedWalletAddress,
      privateKey: validatedPrivateKey,
    };
  };

  const preventEvent = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const processFile = (event: any, isDrop: boolean) => {
    preventEvent(event);
    const files = isDrop ? event.dataTransfer.files : event.target.files;
    readFile(files);
    event.target.value = ''; // allows to send same file again if user typed incorrect password
  };

  const setUserConfigs = () => {
    const user = validatePemFile();
    if (user) {
      const decodedPk = decodePrivateKey(user.privateKey, pemFile);
      finalValidation(user.walletAddress, decodedPk);
    }
  };

  useDidUpdateEffect(() => {
    const encrypt = checkIsEncrypted();
    if (pemFile && !encrypt) {
      setUserConfigs();
    } else if (pemFile && encrypt) {
      setOpen(true);
    }
  }, [pemFile]);

  const finalValidation = async (wallet: string, pk: string) => {
    if (wallet === '') {
      setError('Invalid Wallet Address.');
      resetData();
      return;
    }

    if (pk === '') {
      setError('Invalid Private Key.');
      resetData();
      return;
    }

    const account = new Account(
      wallet,
      pk,
      process.env.REACT_APP_DEFAULT_NODE_HOST ?? undefined,
    );
    setAccount(account);
    setLoading(false);

    if (!wallet.includes('klv')) {
      setError('Invalid Wallet Address.');
      resetData();
      return;
    }
    let signature;
    try {
      signature = await window.kleverWeb.signTx(
        JSON.stringify({
          tx: {} as any,
          privateKey: pk,
        }),
      );
    } catch (error) {
      setError('Invalid Private Key and Password combination.');
      resetData();
      return;
    }

    if (signature.signature === '') {
      setError('Invalid Private Key and Password combination.');
      resetData();
      return;
    }

    sessionStorage.setItem('privateKey', pk);
    sessionStorage.setItem('walletAddress', wallet);
    history.push('/wallet');
  };

  const handleDragEnter = (event: any) => {
    preventEvent(event);

    let count = draggingOverlayCount;
    count++;

    setDragginOverlayCount(count);
    setDragging(true);
  };

  const handleDragLeave = (event: any) => {
    preventEvent(event);

    let count = draggingOverlayCount;
    count--;

    setDragginOverlayCount(count);

    if (count === 0) {
      setDragging(false);
    }
  };

  useEffect(() => {
    injectWasmScript();
    sessionStorage.clear();
  }, []);

  const pemParser = (account: any) => {
    return `-----BEGIN PRIVATE KEY for ${
      account.Address
    }-----\n${encodePrivateKey(account.PrivateKey)}\n-----END PRIVATE KEY for ${
      account.Address
    }-----`;
  };

  const downloadTextFile = (text: string, filename: string) => {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleGeneratePEM = async () => {
    const newAccount = await core.createAccount();
    const pem = pemParser(newAccount);

    downloadTextFile(pem, 'wallet.pem');

    return;
  };

  const resetWindowAndReturnHome = () => {
    setShouldReset(true);
    history.push('/');
  };

  return (
    <DragContainer
      onDragOver={preventEvent}
      onDrop={(event: any) => processFile(event, true)}
      onChange={(event: any) => processFile(event, false)}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <Network>Running on KleverChain {getNetwork()}</Network>
      <Container>
        <Header>
          <Title>Access your Account</Title>
        </Header>
        <Content>
          {open && (
            <PasswordModal
              password={password}
              setPassword={setPassword}
              handleConfirm={setUserConfigs}
              closeModal={resetData}
              handleBack={resetData}
            ></PasswordModal>
          )}
          {!loading && (
            <>
              <ContentTitle>
                <h3>
                  Insert your PEM file to access your wallet and start using the
                  KApps
                </h3>
                <a
                  href="https://docs.klever.finance/klever-blockchain/how-to-create-a-wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  How to get a pem file?
                  <InfoIcon />
                </a>{' '}
              </ContentTitle>
              <ContentBody>
                <InputFile isDragging={isDragging}>
                  <>
                    {window.matchMedia('(max-device-width: 768px)').matches ? (
                      <>
                        <input id="input" type="file" accept=".pem" />
                        <label htmlFor="input">Select file</label>
                      </>
                    ) : (
                      <>
                        <input id="input" type="file" accept=".pem" />
                        <span>
                          Drag and drop your keystore file here, or{' '}
                          <label htmlFor="input">Select file</label>
                        </span>
                      </>
                    )}
                  </>
                </InputFile>
                {error !== '' && (
                  <ErrorContainer>
                    <span>{error}</span>
                  </ErrorContainer>
                )}
              </ContentBody>
              <ButtonContainer>
                <Button
                  styleType="secondary"
                  onClick={resetWindowAndReturnHome}
                >
                  Back to connect options
                </Button>
                <Button styleType="outlined" onClick={handleGeneratePEM}>
                  Generate new account
                </Button>
              </ButtonContainer>
            </>
          )}
          {loading && <Loader />}
        </Content>
      </Container>
    </DragContainer>
  );
};
export default ConnectWallet;
