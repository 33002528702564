import React from 'react';

import { ThemeProvider } from 'styled-components';

import GlobalStyle from './styles/global';
import dark from './styles/themes/dark';

import Routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConnectionProvider } from 'hooks/ConnectionProvider';

//add window methods to global scope
declare global {
  interface Window {
    kleverWeb: {
      decodePEM: any;
      signTx: any;
      broadcastTransactions: any;
      signTransaction: any;
      initialize: any;
      buildTransaction: any;
      provider: any;
      address?: string;
      getWalletAddress: () => string;
      getBalance: () => Promise<number>;
    };
  }
}

const App: React.FC = () => (
  <ThemeProvider theme={dark}>
    <ConnectionProvider>
      <ToastContainer autoClose={2000} position="top-right" />
      <GlobalStyle />
      <Routes />
    </ConnectionProvider>
  </ThemeProvider>
);

export default App;
