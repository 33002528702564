import Loader from 'components/Loading/Loader';
import {
  CardsContainer,
  Container,
  LoaderContainer,
  LoadingTitle,
  Network,
  OptionCard,
  ContentSizer,
} from './styles';
import { useConnection } from 'hooks/ConnectionProvider';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { doIf, getNetwork } from 'utils';

import { DragContainer } from 'pages/ConnectWallet/styles';
import { Title } from 'pages/styles';
import Header from 'components/Pages/Header';
import { toast } from 'react-toastify';

const Choose: React.FC = () => {
  const {
    setExtensionInstalled,
    shouldReset,
    setShouldReset,
    setSelectedConnection,
    setAccount,
  } = useConnection();
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState('');

  const init = async () => {
    if (typeof window !== 'undefined') {
      await doIf(
        () => setExtensionInstalled(true),
        () => handlePemConnection(),
        () =>
          window.kleverWeb?.decodePEM === undefined &&
          window.kleverWeb?.['version'],
        2000,
      );
    }
  };
  const loadPage = async () => {
    await init();
    setLoading(false);
  };

  const loadCorrectProviders = () => {
    if (typeof window !== undefined && window.kleverWeb) {
      window.kleverWeb.provider.api =
        process.env.REACT_APP_DEFAULT_API_HOST ||
        'https://api.testnet.klever.finance';
      window.kleverWeb.provider.node =
        process.env.REACT_APP_DEFAULT_NODE_HOST ||
        'https://node.testnet.klever.finance';
    }
  };
  useEffect(() => {
    loadCorrectProviders();
    if (shouldReset) {
      setShouldReset(false);
    }
    loadPage();
  }, []);

  const handleExtensionConnection = async () => {
    setSelectedConnection('extension');
    await window.kleverWeb.initialize();
    const address: string | undefined = await window.kleverWeb.address;
    if (address?.startsWith('klv') && address?.length === 62) {
      setAccount(address);
      sessionStorage.setItem('walletAddress', address);
      setRedirect('extension');
    } else {
      toast.error('Invalid wallet address, please switch to a klv wallet');
    }
  };

  const handlePemConnection = () => {
    setSelectedConnection('pem');
    setRedirect('connect');
  };

  return loading ? (
    <Container>
      <LoadingTitle>Checking your browser configs...</LoadingTitle>
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </Container>
  ) : (
    <DragContainer>
      <Network>Running on KleverChain {getNetwork()}</Network>
      <ContentSizer>
        <Header>
          <Title>Access your Account</Title>
        </Header>
        {redirect === 'connect' && (
          <Redirect
            to={{
              pathname: '/connect',
            }}
          />
        )}
        {redirect === 'extension' && (
          <Redirect
            to={{
              pathname: '/wallet',
            }}
          />
        )}
        <CardsContainer>
          <OptionCard onClick={() => handlePemConnection()}>
            <p>Connect with your Pem File</p>
          </OptionCard>
          <OptionCard onClick={() => handleExtensionConnection()}>
            <p>Connect through Klever Extension</p>
          </OptionCard>
        </CardsContainer>
      </ContentSizer>
    </DragContainer>
  );
};

export default Choose;
