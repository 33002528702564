import { ButtonsRow, Container, Content } from './styles';
import Button from '../../Button';
import { useHistory } from 'react-router';
import Input from 'components/Input';

interface IPasswordModalProps {
  password: string;
  setPassword: (e: any) => void;
  closeModal: () => void;
  handleConfirm: (e: any) => void;
  handleBack?: () => void;
  BackButtonLabel?: string;
  singleButton?: boolean;
}

const PasswordModal: React.FC<IPasswordModalProps> = ({
  password,
  setPassword,
  closeModal,
  handleConfirm,
  handleBack,
  BackButtonLabel = 'Back',
}) => {
  const history = useHistory();

  const handleClose = () => {
    closeModal();
  };

  const handleGoBack = () => {
    history.goBack();
    closeModal();
  };

  return (
    <Container onMouseDown={handleClose}>
      <Content onMouseDown={e => e.stopPropagation()}>
        <h4>Your Pem file is encrypted, insert your password please.</h4>
        <Input
          title="Password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <ButtonsRow>
          {
            <Button onClick={handleBack ? handleBack : handleGoBack}>
              {BackButtonLabel}
            </Button>
          }
          {
            <Button onClick={(pemFile: string) => handleConfirm(pemFile)}>
              Confirm
            </Button>
          }
        </ButtonsRow>
      </Content>
    </Container>
  );
};
export default PasswordModal;
