import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const LoadingTitle = styled.h1`
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const OptionCard = styled.div`
  border: solid 2px purple;
  border-radius: 5px;
  max-width: 35%;
  height: 30%;
  min-height: 300px;
  @media (max-width: 768px) {
    min-height: 200px;
  }
  transition: transform 0.5s;
  background: rgb(153, 38, 108);
  background: linear-gradient(
    45deg,
    rgb(172, 53, 182) 57%,
    rgb(36, 38, 79) 95%
  );
  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  margin: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: white;
    padding: 5px;
  }
  @media (max-width: 768px) {
    min-width: 60%;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  margin: auto;
  padding: 2rem 0;
  width: 90%;

  height: 100vh;
`;

export const Network = styled.div`
  color: ${props => props.theme.navbar.text};
  padding-top: 5px;
  font-size: 1rem;
  padding-left: 5px;
`;

export const ContentSizer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    width: 80vw;
  }
`;
