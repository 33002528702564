import {
  Account,
  IAssetTrigger,
  IBuyOrder,
  IConfigITO,
  ICreateAsset,
  ICreateMarket,
  IDelegate,
  ISellOrder,
  ITransactionProps,
  ITransactionResponse,
  IUndelegate,
  IUnfreeze,
  IWithdraw,
} from '@klever/sdk';
import { IBroadcastResponse } from 'types';
import { IFreezeExtension } from 'types';

export class ExtensionAccount extends Account {
  constructor(address: string, host?: string) {
    super(address, '', host);
  }

  getAddress(): string {
    return window.kleverWeb.getWalletAddress();
  }

  getBalance(): Promise<number> {
    return window.kleverWeb.getBalance();
  }

  signTx(tx: ITransactionResponse): Promise<ITransactionResponse> {
    return window.kleverWeb.signTransaction(tx);
  }

  broadcast(signedTxArr: ITransactionResponse[]) {
    return window.kleverWeb.broadcastTransactions(signedTxArr);
  }

  async processTx(payload: any, metadata: any) {
    const unsignedTx = await window.kleverWeb.buildTransaction(
      payload,
      metadata,
    );
    const signedTx = await this.signTx(unsignedTx);
    return (await this.broadcast([signedTx])).data;
  }

  sendCreateAsset(
    payload: ICreateAsset,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    const newPayload = { ...payload, ownerAddress: payload.owner };
    return this.processTx(
      [{ type: 1, payload: newPayload }],
      [props?.metadata],
    );
  }

  sendFreeze(
    payload: IFreezeExtension,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx(
      [{ type: 4, payload: { ...payload, kda: payload.assetId } }],
      [props?.metadata],
    );
  }

  sendUnfreeze(
    payload: IUnfreeze,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 5, payload }], [props?.metadata]);
  }

  sendDelegate(
    payload: IDelegate,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 6, payload }], [props?.metadata]);
  }

  sendUndelegate(
    payload: IUndelegate,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 7, payload }], [props?.metadata]);
  }

  sendWithdraw(
    payload: IWithdraw,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 8, payload }], [props?.metadata]);
  }

  sendAssetTrigger(
    payload: IAssetTrigger,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 11, payload }], [props?.metadata]);
  }

  sendConfigITO(
    payload: IConfigITO,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 15, payload }], [props?.metadata]);
  }

  sendBuyOrder(
    payload: IBuyOrder,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 17, payload }], [props?.metadata]);
  }

  sendSellOrder(
    payload: ISellOrder,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 18, payload }], [props?.metadata]);
  }

  sendCreateMarketplace(
    payload: ICreateMarket,
    props?: ITransactionProps | undefined,
  ): Promise<IBroadcastResponse | ITransactionResponse[]> {
    return this.processTx([{ type: 20, payload }], [props?.metadata]);
  }
}
