import { darken, lighten } from 'polished';
import { BsInfoCircle } from 'react-icons/bs';
import styled from 'styled-components';

export const Logo = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

export const Container = styled.div`
  margin: auto;
  padding: 2rem 0;
  width: 90%;

  height: 100%;
`;

export const Content = styled.div`
  height: 60%;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;

  padding-top: 2rem;

  width: 100%;
`;

export const ContentTitle = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  h1 {
    font-weight: 500;
  }

  h3 {
    font-weight: 400;
  }

  a {
    color: ${({ theme }) => theme.gray};
    text-decoration: none;
    transition: 0.2s ease-in-out;

    width: 100%;
    text-align: right;

    font-weight: 400;
    font-size: 0.8rem;

    :visited {
      color: ${({ theme }) => theme.gray};
    }
    :hover {
      color: ${props => lighten(0.2, props.theme.primary)};
      text-decoration: dotted 1px underline;
    }
  }
`;

export const InfoIcon = styled(BsInfoCircle)`
  height: 0.75rem;
`;

export const ContentBody = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 80%;
  }
`;

export const InputFile = styled.div<{ isDragging: boolean }>`
  padding: 1.25rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw;

  @media (max-width: 830px) {
    width: 80vw;
  }
  border: 1px dashed ${props => props.theme.card.border};
  border-radius: 0.5rem;

  background-color: ${props => lighten(0.1, props.theme.background)};

  background-color: ${props =>
    props.isDragging && darken(0.025, props.theme.card.background)} !important;
  border-color: ${props =>
    darken(props.isDragging ? 0.01 : 0, props.theme.card.border)};

  color: ${props => props.theme.white};
  font-weight: 400;
  font-size: 0.9rem;

  transition: 0.2s ease;

  &:hover {
    border-color: ${props => darken(0.05, props.theme.card.border)};
    background-color: ${props => darken(0.025, props.theme.card.background)};
  }

  input {
    display: none;
  }

  label {
    cursor: pointer;

    color: #f372ff;
    font-weight: 500;
  }
`;

export const ErrorContainer = styled.div`
  height: 1rem;
  width: 100%;

  margin: -0.5rem 0 1.25rem;
  padding: 1.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.5rem;

  background-color: ${props => props.theme.error.background};

  color: ${props => props.theme.error.border};
`;

export const DragContainer = styled.div`
  width: 100%;

  z-index: 90;
`;

export const ButtonContainer = styled.div`
  padding-bottom: 0.3rem;
  display: flex;
  justify-content: center;
  width: 40vw;
  @media (max-width: 830px) {
    width: 80vw;
  }
  button {
    padding: 1rem 0.85rem;
    box-sizing: border-box;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
`;

export const TextAreaJson = styled.textarea`
  width: 100%;
  height: 30rem;
  background-color: #24264f;
  color: white;
  border-radius: 0.2rem;
  box-sizing: border-box;
  border-width: 2px;
  border-style: dotted;
  resize: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media (max-width: 830px) {
    flex-direction: column;
  }
`;

export const LeftContentContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-right: 0.2rem;
`;

export const RightContentContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.2rem;
  h3 {
    color: #f1fa8c;
    margin: 0.5rem;
  }

  pre {
    height: 30rem;
    border-radius: 0.2rem;
    width: 100%;
    max-width: 40vw;
    overflow: auto;
    @media (max-width: 830px) {
      max-width: 80vw;
    }
  }
`;

export const ContentContainerHeader = styled.div`
  height: 7rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
`;

export const LoaderContainer = styled.div`
  margin-top: 5rem;
  height: 25rem;
`;
